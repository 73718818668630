import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';


export const InfoContainer = styled.div`
  color: #fff;
  /* background: ${({lightBg}) => (lightBg ? 'linear-gradient(#092A49, whitesmoke)' : 'linear-gradient(whitesmoke, #092A49)')}; */
  background: ${({lightBg}) => (lightBg ? 'linear-gradient(#041524, whitesmoke)' : 'linear-gradient(whitesmoke, #041524)')};

  @media screen and (max-width: 768px) {
      padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 675px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 750px;
  }

  @media screen and (max-width: 625px) {
    height: 675px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

   @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
  } 
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  display: flex;
  justify-content: center;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

export const TopLine = styled.div`
  color: #01bf71;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  text-align: center;
  color: ${({lightText}) => (lightText ? '#f7f8fa' : '#fff')};

  @media screen and (max-width: 1100px) {
    font-size: 42px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 36px;
  }

  @media screen and (max-width: 900px) {
    font-size: 32.5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }

  @media screen and (max-width: 650px) {
    font-size: 40px;
  }

  @media screen and (max-width: 500px) {
    font-size: 32px;
  }

  @media screen and (max-width: 410px) {
    font-size: 24px;
  }
`;


export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 24px;
  color: ${({darkText}) => (darkText ? '#fff' : '#fff')};

  @media screen and (max-width: 900px) {
    font-size: 16px;
  }

  @media screen and (max-width: 650px) {
    font-size: 15px;
  }

  @media screen and (max-width: 480px) {
    font-size:14px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 70%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const Divider = styled.hr`
  height: 0.3rem;
  max-width: 3.25rem;
  margin: 1.5rem auto;
  background-color: #f4623a;
  border-color: #f4623a;
  opacity: 1;
`;