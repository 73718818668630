import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.div`
  /* background: #092A49; */
  background: #041524;
`;

export const FooterWrap = styled.div`
  padding: 30px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto 0 auto;

  @media screen and (max-width: 820px) {
      flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: 24px;

  @media screen and (max-width: 820px) {
  margin-left: 0;
  margin-bottom: 16px;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  @media screen and (max-width: 820px) {
  margin-bottom: 16px;
  }
`;
