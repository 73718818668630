import styled from "styled-components";

export const MailSection = styled.form`
  background: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 990px) {
    padding: 0;
  }

  @media screen and (max-width: 768px) {
   height: 900px;
  }
`;

export const MailContainer = styled.div`
  padding-right: 3rem;
  padding-left: 3rem;
  width: 80%;

  @media screen and (max-width: 1100px) {
    width: 100%;
    padding: 0;
  }
`;

export const MailHeaderContainer = styled.div`
  justify-content: center;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const MailHeader = styled.div`
  text-align: center;
  max-width: 100%;
`;

export const MailH2 = styled.h2`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 600;
  line-height: 1.2;
`;

export const MailP = styled.p`
  margin-bottom: 2rem;
  margin-right: auto;
  margin-left: auto;
  width: 70%;
  font-size: 18px;

  @media screen and (max-width: 520px) {
    font-size: 16px;
  }

`;

export const MailBodyContainer = styled.div`
  margin-bottom: 3rem;
  justify-content: center;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const MailBody = styled.div`
  flex-shrink: 0;
  width: 70%;

  @media screen and (max-width: 750px) {
    width: 90%;
  }
`;

export const MailForm = styled.div`
  display: block;
  margin-top: 0em;
`;

export const InputContainer = styled.div`
  margin-bottom: 1rem;
  position: relative;
`;

export const InputLabel = styled.label`
  /* position: absolute;
  margin-left: 250px;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem; */
  display: none;
  color: red;
  font-weight: bold;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
`;


export const MailName = styled.input`
  display: block;
  margin-bottom: 10px;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  /* line-height: 1.5; */
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out box-shadow 0.15s ease-in-out;
`;

export const MailEmail = styled.input`
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 10px;
  /* line-height: 1.5; */
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out box-shadow 0.15s ease-in-out;  
  
`;

export const MailSubject = styled.input`
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  /* line-height: 1.5; */
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out box-shadow 0.15s ease-in-out; 
`;

export const MailText = styled.textarea`
  margin-bottom: 10px;
  height: 10rem;
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  /* line-height: 1.5; */
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const TextLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
`;


export const Button = styled.input`
  border-radius: 50px;
  /* background: #092A49; */
  background: #041524;
  white-space: nowrap;
  padding: 16px 30px;
  color: #fff;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
      transition: all 0.2s ease-in-out;
      background: #f4623a;
  }

  @media screen and (max-width: 600px) {
    padding: 12px 30px;
  }
`;

export const Divider = styled.hr`
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1rem auto;
  background-color: black;
  opacity: 1;
`;