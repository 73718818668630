import React, { useState } from 'react'
import { MailSection, MailContainer, MailHeaderContainer, MailHeader,
        MailH2, MailP, MailBodyContainer, MailBody, MailForm, InputContainer, 
        MailName, InputLabel, MailEmail, MailSubject, MailText, Button, Divider } from './WebMailElements';
import emailjs from 'emailjs-com';

const WebMail = () => {

    const [values, setValues] = useState({
        name: '',
        email: '',
        message: ''
        });

        const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
            });
          };

   

    function sendEmail(e) {

        e.preventDefault();

        if (!values.name.trim()) {
            document.getElementById('nameInput').innerHTML = 'Name required';
            document.getElementById('nameInput').style.display = 'block';
            document.getElementById('name').style.borderColor = 'red';
            return;
         } else { 
            document.getElementById('nameInput').innerHTML = '';
            document.getElementById('name').style.borderColor = ''; 
        }

        if (!values.email) {
            document.getElementById('emailInput').innerHTML = 'Email required';
            document.getElementById('emailInput').style.display = 'block';
            document.getElementById('email').style.borderColor = 'red';
            return;
          } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            document.getElementById('emailInput').innerHTML = 'Email address is invalid';
            document.getElementById('emailInput').style.display = 'block';
            document.getElementById('email').style.borderColor = 'red';
            return;
          } else  {
            document.getElementById('emailInput').innerHTML = '';
            document.getElementById('email').style.borderColor = ''; 
        }

        if (!values.message) {
            document.getElementById('message').style.borderColor = 'red';
            return;
         } else { 
            document.getElementById('message').style.borderColor = ''; 
        }
        

        emailjs.sendForm('service_8n49kyo', 'template_hlj86ni', e.target, 'user_Jmrq8ZKu2ZJjnMoAWCx7h')
        .then((res) => {
            document.getElementById('h2').innerHTML = 'Thanks for your message!';
            document.getElementById('p').innerHTML ='Message Sent!';
            console.log(res.text);
        }, (err) => {
            document.getElementById('p').innerHTML = 'Message Error!';
            console.log(err.text);
        });
    } 



    return (
        <MailSection id='contact' onSubmit={sendEmail}>
            <MailContainer>
                <MailHeaderContainer>
                    <MailHeader>
                        <MailH2 id='h2'>Let's Get in Touch</MailH2>
                        <Divider />
                        <MailP id='p'>Got something in mind?<br/> Send me an email and I will get back to you as soon as possible!</MailP>
                    </MailHeader>
                </MailHeaderContainer>
                <MailBodyContainer>
                    <MailBody>
                        <MailForm>
                            <InputContainer>
                                <MailName id='name' type="text" name='name' placeholder='Full Name' value={values.name} onChange={handleChange}/>
                                <InputLabel id='nameInput' for='email'></InputLabel>
                            </InputContainer>
                            <InputContainer>
                                <MailEmail id='email' type="text" name='email' placeholder='Email Address' value={values.email} onChange={handleChange}/>
                                <InputLabel id='emailInput' for='email'></InputLabel>
                            </InputContainer>
                            <InputContainer>
                                <MailSubject id='subject' type="text" name='subject' placeholder='Subject'/>
                                <InputLabel for='subject' id='subjInput'></InputLabel>
                            </InputContainer>
                            <InputContainer>
                                <MailText id='message' type="text" name='message' placeholder='Message' value={values.message} onChange={handleChange}/>
                                <InputLabel for='message' id='messgInput'></InputLabel>
                            </InputContainer>
                            <Button type='submit' value='Submit'/>
                        </MailForm>
                    </MailBody>
                </MailBodyContainer>
            </MailContainer>
        </MailSection>
    )
}

export default WebMail
