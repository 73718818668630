import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';
// import icon from '../../images/aircraft.svg'
// import icon1 from '../../images/aircraft1.svg'



export const WelcomeContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 101vh;
  position: relative;
  z-index: 1;

  :before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), 
                  linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
      z-index: 2;
  }

  /* @media not all and (min-resolution:.001dpcm) { 
     @supports (-webkit-appearance:none) {
        height: 1000px;
    }
} */

/* @media (min-resolution: .001dpcm) 
{ _:-o-prefocus, .selector {
   height: 1000px;
  } 
} */
`;


export const WelcomeBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
`; 

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  
  /* @media screen and (max-width: 500px) {
    display: none;
  } */
`;

// export const ImgBg = styled.div`
//     -o-object-fit: cover;
//     object-fit: cover;
//     background: #092A49 no-repeat center;
//     background-image: url(${icon});
//     width: 100%;
//     height: 100%;
//     @media screen and (max-width: 1000px) {
//       background-image: url(${icon1});
//       background-repeat: no-repeat;
//       background-position: cover;
//     }
// `;

export const WelcomeContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WelcomeH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
      font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
}
`;

export const WelcomeP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
}

@media screen and (max-width: 480px) {
  font-size: 18px;
}
`;

export const WelcomeBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
