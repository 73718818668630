import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import {
    InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, Heading, 
    Subtitle, BtnWrap, Column2, Img, ImgWrap, ArrowForward, ArrowRight,Divider
} from './InfoElements';


const InfoSection = ({lightBg, id, imgStart, lightText, headline,
    darkText, description, buttonLabel, img, alt, primary, dark, dark2, description1, description2 }) => {

        const [hover, setHover] = useState(false);

        const onHover = () => {
            setHover(!hover);
        };

    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                        <TextWrapper>
                            {/* <TopLine>{topLine}</TopLine> */}
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Divider />
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <Subtitle darkText={darkText}>{description1}</Subtitle>
                            <Subtitle darkText={darkText}>{description2}</Subtitle>
                            <BtnWrap>
                                <Button to='contact'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact={true}
                                offset={-80}
                                // primary={primary ? 1 : 0}
                                // dark={dark ? 1 : 0}
                                // dark2={dark2 ? 1 : 0}
                                onMouseEnter={onHover} onMouseLeave={onHover} 
                                >{buttonLabel}{hover ? <ArrowForward /> : <ArrowRight />}</Button>
                            </BtnWrap>
                        </TextWrapper>
                        </Column1>
                        <Column2>
                        <ImgWrap>
                        <Img src={img} alt={alt} />
                        </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
