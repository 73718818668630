import React, { useState } from 'react';
import Video from '../../videos/video.mp4';
import { Button } from '../ButtonElements';
import { 
    WelcomeContainer, WelcomeBg, VideoBg,
    WelcomeContent, WelcomeH1, WelcomeP, 
    WelcomeBtnWrapper, ArrowForward, ArrowRight
} from './WelcomeElements';

const WelcomeSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };



    return (
        <WelcomeContainer id='home'>
            <WelcomeBg>
                <VideoBg autoPlay loop muted playsInline src={Video} type='video/mp4' />
            </WelcomeBg>
            <WelcomeContent>
            
                <WelcomeH1>Hi I'm Francesco</WelcomeH1>
                <WelcomeP>Welcome to my WebSpace</WelcomeP>
                <WelcomeBtnWrapper>
                    <Button to='about' 
                    onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' solid='true'
                    borderC='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}
                    > Find out More {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </WelcomeBtnWrapper>
            </WelcomeContent>
        </WelcomeContainer>
    )
}

export default WelcomeSection;
