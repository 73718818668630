import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
// import { Route } from 'react-router-dom';
import Home from './pages';

function App() {
  return (
    <Router>
       <Home />
       {/* <Route exact path="/Gazetteer/index.html" render={() => {window.location.href="/Gazetteer/index.html"}} /> */}
       {/* <Route exact path="/PersonnelRegistry/libs/php/mainPage/login.php" render={() => {window.location.href="/PersonnelRegistry/libs/php/mainPage/login.php"}} /> */}
       {/* <Route exact path="https://google.com" render={() => {window.location.href="https://google.com"}} /> */}
    </Router>
    
  );
}

export default App;
