import styled from "styled-components";
import { Link } from "react-scroll";

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({primary}) => (primary ? '#fff' : '#f4623a')};//#010606
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#f4623a' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  border-style: ${({solid}) => (solid ? 'solid' : 'none')};
  border-color: ${({borderC}) => (borderC ? '#fff' : '#f4623a')};
  outline: none;
  /* border: none; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
      transition: all 0.2s ease-in-out;
      background: #fff;
      color: #f4623a;
  }
`;