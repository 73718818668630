import styled from "styled-components";

export const SkillsContainer = styled.div`
  background: whitesmoke;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-bottom: 8rem;
  padding-top: 1.5rem;

  @media screen and (max-width: 1000px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

export const SkillSection = styled.div`
  /* height: 550px; */
`;


export const SkillsWrapper = styled.div`
  margin-top: 3.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
`;

export const Skills = styled.div`
  flex: 0 0 auto;
  width: 16.6666666667%;
  text-align: center;
  margin-bottom: 3rem;
  margin-left: 1rem;

  @media screen and (max-width: 700px) {
    width: 50%;
    margin-bottom: 0;
    margin-left: 0;
  }
`;

export const SkillsBox = styled.div`
  margin-top: 3rem;
  

  @media screen and (max-width: 700px) {
    margin-top: 1rem;
  }
`;

export const SkillsSVG = styled.div`
  margin-bottom: 0.5rem;

`;

export const SkillsH3 = styled.h3`
  font-size: calc(1.075rem + 0.3vw);
  margin-bottom: 0.5rem;

`;

export const SkillsH2 = styled.h2`
  margin-top: 4rem;
  font-size: calc(1.925rem + 0.9vw);
  text-align: center;


  @media screen and (max-width: 800px) {
    font-size: calc(1.675rem + 0.3vw);
  }

  @media screen and (max-width: 400px) {
    font-size: calc(1.400rem + 0.3vw);
  }

  @media screen and (max-width: 350px) {
    font-size: calc(1.200rem + 0.3vw);
  }
`;

export const Divider = styled.hr`
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1rem auto;
  background-color: black;
  opacity: 1;
`;