import React, { useState, useEffect} from 'react';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { IconContext } from 'react-icons/lib';
import { IoRocketSharp } from 'react-icons/io5';



import { 
    Nav,
    NavbarContainer, 
    NavLogo, 
    MobileIcon,
    NavMenu, 
    NavItem, 
    NavLinks,
} from './NavbarElements'


const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY > 800) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
          <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/'  onClick={toggleHome}><IoRocketSharp size={40} color={'#f4623a'}/></NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to='about' 
                        smooth={true} duration={500} spy={true} exact='true' offset={-80}
                        >About</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='skills'
                         smooth={true} duration={500} spy={true} exact='true' offset={-80}
                         >Skills</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='projects'
                         smooth={true} duration={500} spy={true} exact='true' offset={-80}
                         >Projects</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='contact'
                         smooth={true} duration={500} spy={true} exact='true' offset={-80}
                         >Contact</NavLinks>
                    </NavItem>
                </NavMenu>
            </NavbarContainer>
          </Nav>
        </IconContext.Provider>
            
        </>
    )
}

export default Navbar
