import React from "react";
import Clock from "../Clock/clock";
import { FaArrowDown } from 'react-icons/fa';
import {ExtraContainer, ClockContainer, ClockWrapper, ClockCaption, ArrowContainer, ArrowWrapper1, ArrowWrapper2 } 
from "./ExtraElements";



const Extra = () => {

    return (
    <ExtraContainer>
        <ClockContainer>
        <ClockWrapper>
        <Clock />
        </ClockWrapper>
       <ClockCaption>Time's running don't waste it</ClockCaption>
       <ClockCaption>Get in touch</ClockCaption>
       </ClockContainer>

        <ArrowContainer>
            <ArrowWrapper1>
                <FaArrowDown />
            </ArrowWrapper1>
            <ArrowWrapper2>
                <FaArrowDown />
            </ArrowWrapper2>
            <ArrowWrapper1>
                <FaArrowDown/>
            </ArrowWrapper1>
        
      </ArrowContainer>
      </ExtraContainer>
    )
}

export default Extra;