import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FooterContainer, FooterWrap, SocialLogo, WebsiteRights } from './FooterElements';
import { IoRocketSharp } from 'react-icons/io5';

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }


    return (
        <FooterContainer>
            <FooterWrap>
            <SocialLogo to='/' onClick={toggleHome}>
            <IoRocketSharp size={40} />
                        </SocialLogo>
                        {/* <SocialLogo to='/' onClick={toggleHome}>Back To Top</SocialLogo> */}
                        <WebsiteRights>Made with &hearts; and React by Francesco Mekni {new Date().getFullYear()} All Rights Reserved.</WebsiteRights>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
