import styled from "styled-components";



export const ExtraContainer = styled.div`
  /* padding-top: 6.5rem; */
  /* padding-bottom: 5rem; */
  padding-left: 2rem;
  padding-right: 2rem;
 
  background: linear-gradient(#041524, whitesmoke);
  height: 500px;
`;

export const ClockContainer = styled.div`
  padding-top: 100px;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;

  @media screen and (max-width: 330px) {
    padding-top: 70px;
  }
`;

export const ClockWrapper = styled.div`
  color: #f4623a;
  padding-bottom: 15px;
`;
export const ClockCaption = styled.h3`
    @media screen and (max-width: 590px) {
    font-size: 25px;
  }
  @media screen and (max-width: 450px) {
    font-size: 20px;
  }
  @media screen and (max-width: 370px) {
    font-size: 17px;
  }
  @media screen and (max-width: 330px) {
    font-size: 14px;
  }
`;

export const ArrowContainer = styled.div`
  margin-top: 40px;
  color: #f4623a;
  font-size: 50px;
  display: flex;
  justify-content: space-evenly;

`;

export const ArrowWrapper1 = styled.span`

  animation: blinker 0.5s linear infinite;
  color: #f4623a;
  font-size: 40px;
  font-weight: bold;
  font-family: sans-serif;
  
  @keyframes blinker {
    100% { opacity: 1;  color: white;}
} 
`;

export const ArrowWrapper2 = styled.span`

animation: blinker1 0.5s linear infinite;
color: white;
font-size: 40px;
font-weight: bold;
font-family: sans-serif;

@keyframes blinker1 {
  100% { opacity: 1; color: #f4623a; }
} 
`;
