import React from 'react';
import Icon1 from '../../images/GZT1.png';
import Icon2 from '../../images/computer.png';
import Icon3 from '../../images/issue-tracker.jpeg';
import { PortfolioContainer, PortfolioH1, PortfolioWrapper, PortfolioCard,
        PortfolioIcon, PortfolioH2, PortfolioP, PortfolioCaption, Divider, 
         } from './PortfolioElements';

const Portfolio = () => {
    return (
      <PortfolioContainer id='projects'>
            <PortfolioH1>Projects Portfolio</PortfolioH1>
            <Divider />
            <PortfolioWrapper id='wrapper'>
            <PortfolioCard href="https://gazetteer.francescomekni.com"
                                  target='_blank'
                                  rel="noopener"
                                  aria-label='Gazetteer' id='card1'>
                      <PortfolioIcon src={Icon1}/>
                      <PortfolioCaption>
                        <PortfolioH2>Gazetteer</PortfolioH2>
                        <PortfolioP>
                          A mobile-friendly app that provides profiling for all countries via the presentation
                          of geographical, financial and weather-related data through its extensive API use.
                        </PortfolioP>
                      </PortfolioCaption>
                </PortfolioCard>

                <PortfolioCard href="https://personnel-registry.francescomekni.com/libs/php/mainPage/login.php"
                                  target='_blank'
                                  rel="noopener"
                                  aria-label='Personnel Registry' id='card2'>
                    <PortfolioCaption>
                       <PortfolioH2>Personnel Registry</PortfolioH2>
                       <PortfolioP>
                        This application allows users to make CRUD requests to a relational database. 
                        Thanks to the use of sessions and authentication 
                        each registered user will be able to interact with their own data. 
                        </PortfolioP>
                    </PortfolioCaption> 
                    <PortfolioIcon src={Icon2}/>
                </PortfolioCard>

                <PortfolioCard  href="https://issue-tracker.francescomekni.com/login"
                                  target='_blank'
                                  rel="noopener"
                                  aria-label='Issue-Tracker' id='card3'>
                      <PortfolioIcon src={Icon3}/>
                      <PortfolioCaption>
                        <PortfolioH2>Issue Tracking System</PortfolioH2>
                        <PortfolioP>
                          A full-fledged Laravel application that allows companies to keep track, manage, and mantain
                          lists of internal issues. 
                        </PortfolioP>
                      </PortfolioCaption>
                </PortfolioCard>
                
        </PortfolioWrapper>
      </PortfolioContainer>
    )
}

export default Portfolio
