import { useState, useEffect } from 'react';


const Clock = () => {
    const [date, setDate] = useState(new Date());
  
   //Replaces componentDidMount and componentWillUnmount
    useEffect(() => {
    let timerID = setInterval( () => tick(), 1000 );

    return function cleanup() {
        clearInterval(timerID);
      };
   }, []);
  
    function tick() {
      setDate(new Date());
    }
  
    return (
        <div>
          <h2>{date.toLocaleTimeString()}</h2>
        </div>
    );
}
  
export default Clock;