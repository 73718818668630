import React from 'react'
import { SkillSection, SkillsContainer, SkillsWrapper, Skills, SkillsBox, SkillsSVG, SkillsH3, SkillsH2, Divider } from './SkillsElements';
import { FaHtml5, FaCss3Alt, FaJs, FaReact, FaDatabase, FaPhp, FaBootstrap, FaGit, FaGithub, FaLaravel, FaWordpress, FaSass } from 'react-icons/fa';
import { SiJquery } from "react-icons/si";


const SkillsSection = () => {
    return (
        <SkillsContainer id='skills'>
          <SkillSection>
              <SkillsH2>Technologies I use every day</SkillsH2>
              <Divider />
            <SkillsWrapper>
            <Skills>
                    <SkillsBox>
                      <SkillsSVG><FaPhp size={70} color={'#f4623a'} /></SkillsSVG>
                      <SkillsH3>PHP</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                <SkillsBox>
                        <SkillsSVG><FaWordpress size={70} color={'#f4623a'} /></SkillsSVG>
                        <SkillsH3>Wordpress</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                    <SkillsBox>
                      <SkillsSVG><FaJs size={70} color={'#f4623a'} /></SkillsSVG>
                      <SkillsH3>Javascript</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                    <SkillsBox>
                        <SkillsSVG><SiJquery size={70} color={'#f4623a'} /></SkillsSVG>
                        <SkillsH3>Jquery</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                    <SkillsBox>
                        <SkillsSVG><FaSass size={70} color={'#f4623a'} /></SkillsSVG>
                        <SkillsH3>SASS</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                    <SkillsBox>
                        <SkillsSVG><FaHtml5 size={70} color={'#f4623a'} /></SkillsSVG>
                        <SkillsH3>HTML</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                    <SkillsBox>
                        <SkillsSVG><FaCss3Alt size={70} color={'#f4623a'} /></SkillsSVG>
                        <SkillsH3>CSS</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                <SkillsBox>
                <SkillsSVG>
                        <FaBootstrap size={70} color={'#f4623a'} /></SkillsSVG>
                        <SkillsH3>Bootstrap</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                    <SkillsBox>
                        <SkillsSVG><FaDatabase size={70} color={'#f4623a'} /></SkillsSVG>
                        <SkillsH3>SQL</SkillsH3>
                    </SkillsBox>
                </Skills>
                <Skills>
                    <SkillsBox>
                        <SkillsSVG><FaLaravel size={70} color={'#f4623a'} /></SkillsSVG>
                        <SkillsH3>Laravel</SkillsH3>
                    </SkillsBox>
                </Skills>
            </SkillsWrapper>
            </SkillSection>
        </SkillsContainer>
    )
}

export default SkillsSection;
