import styled from "styled-components";
import { Link } from 'react-router-dom';

export const PortfolioContainer = styled.div`
  padding-top: 6.5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* background: #092A49; */
  background: #041524;
  height: 700px;

  @media screen and (max-width: 768px) {
    height: auto;
  }

  @media screen and (max-width: 490px) {
    padding-bottom: 15rem;
  }
`;


export const PortfolioH1 = styled.h1`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: calc(1.925rem + 0.9vw);
  text-align: center;
  color: #f4623a;

  @media screen and (max-width: 480px) {
      font-size: 2rem;
  }
`;


export const PortfolioWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    dispaly: flex;
    flex-direction: column;
    align-items: center;
  }
`;




export const PortfolioA = styled.a`
  position: relative;
  display: block;
  cursor: pointer;
`;



export const PortfolioCard = styled.a`
  position: relative;
  display: block;
  height: 100%;
  color: black;
  /* @media screen and (max-width: 768px) {
   padding-bottom: 1rem;
  } */
`;


export const PortfolioIcon = styled.img`
  width: 100%;
  height: 100%;
`;


export const PortfolioCaption = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0;
    background: whitesmoke;

    &:hover{ 
    opacity: 1;
    text-align: center;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    transition-delay: 0s;
    }
`;

export const PortfolioH2 = styled.h2`
  font-size: 1.8rem;
  padding: 10px;

  @media screen and (max-width: 600px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 350px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 300px) {
    font-size: 0.9rem;
  }
`;

export const PortfolioP = styled.p`
  font-size: 1.2rem;
  text-align: center;
  padding: 0 20px;

  @media screen and (max-width: 1180px) {
    font-size: 1.05rem;
  }

  @media screen and (max-width: 1000px) {
    font-size: .93rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 350px) {
    font-size: 0.7rem;
  }
  @media screen and (max-width: 300px) {
    font-size: 0.6rem;
  }
`;

export const Divider = styled.hr`
  height: 0.2rem;
  max-width: 3.25rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  opacity: 1;
`;

export const PDivider = styled.hr`
  max-width: 3.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  opacity: 0;
`;