import Icon1 from '../../images/space.svg';
import Icon2 from '../../images/design1.svg';
// import Icon3 from '../../images/svg-3.svg';

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine:'Something about me',
    headline: 'Something About Me',
    description: 'I am a London based Full Stack Developer.',
    description1: 'Web Development is something that has fascinated me ever since I wrote my first line of code. ', 
    description2: 'Always ready to take a step further in order to get a task done, and to take on new challenges and learn.',
    buttonLabel: `Let's get started`,
    imgStart: false,
    img: Icon1,
    alt: 'Rocket',
    primary: false,
    dark: false,
    darkText: true
};

export const homeObjTwo = {
    // id: 'skills',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'my skills',
    headline: 'What I Like To Do',
    description: 'I like to bring new ideas to life whether they are for a cutting edge web-mobile app or a solid user interface, always keeping the attention on the smallest detail.',
    description1: 'My knowledge of both Front and Back-End side allows me to code full-stack projects perfectly tailored for every need where I showcase my ability to use a wide range of programming languages.',
    description2: '',
    buttonLabel: `Let's get started`,
    imgStart: true,
    img: Icon2,
    alt: 'Design',
    primary: false,
    dark: false,
    darkText: true
};

export const homeObjThree = {
    id: 'skills',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: '',
    description: '',
    // buttonLabel: 'Start Now',
    imgStart: false,
    // img: Icon3,
    // alt: 'Car',
    primary: false,
    dark: false,
    darkText: true
};