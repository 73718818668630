import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import WelcomeSection from '../components/Welcome';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo } from '../components/InfoSection/Data';
import Portfolio from '../components/Portfolio';
import Extra from '../components/Extra';
import WebMail from '../components/WebMail/Index';
import SkillsSection from '../components/Skills';
import Footer from '../components/Footer';


const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return (
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <WelcomeSection />
            <InfoSection {...homeObjOne}/>
            <InfoSection {...homeObjTwo}/>
            <SkillsSection />
            <Portfolio />
            <Extra />
            <WebMail />
            <Footer />
        </div>
    )
}

export default Home
